<template>
  <div>
    <div class="row">
      <div class="col text-center py-4">
        <router-link :to="{ name: 'Home' }">
          <picture>
            <img src="https://api.dabuho.com/storage/img/data/logo-dabuho.png" alt="Logo-dabuho" />
          </picture>
        </router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col col-sm-6 col-md-6 col-lg-4 col-xl-4 content_invite">
        <div class="row box_invite">
          <div class="col text-center">
            <picture>
              <img class="icon_versus" src="https://api.dabuho.com/storage/img/data/icon-versus-dabuho.png" alt="Icon-versus-dabuho" />
            </picture>
          </div>
          <div class="w-100"></div>
            <div class="col text-center invite_text py-4">
            <p>¡Has recibido <br />una solicitud!</p>
          </div>
          <div class="w-100"></div>
          <div class="col">
            <div class="col text-center"><p>¿Aceptas el versus?</p></div>
            <button
              type="submit"
              class="btn-block button_actions_invite button_click"
              v-on:click="acceptVersus">
              Sí
            </button>
            <button
              type="submit"
              class="btn-block button_actions_invite button_click"
              v-on:click="cancelVersus">
              No, gracias
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-4"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      info: "",
    };
  },
  async created() {
    await this.getLogged();
    await this.getInvite();
  },
  methods: {
    getInvite() {
      const link = this.$router.currentRoute.params.url;
      this.$store.commit("setLinkVersus", link);
      this.$store.state.linkVersusStatus = true;
    },
    acceptVersus() {
      const url = this.appDomainApi1 + "versus/start";
      this.$store.state.linkVersusStatus = false;
      axios
        .post(url, { creator: this.$router.currentRoute.params.url })
        .then((response) => {
          if (response.data.url) {
            this.$router.push({
              name: "Versus",
              params: { url: response.data.url },
            });
          } else {
            this.info = response.data.msj;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getLogged() {
      if (!this.loggedIn) {
        console.log("loggedIn");
        this.$router.push({
          name: "login",
        });
      }
    },
    cancelVersus() {
      this.$store.state.linkVersus = null;
      this.$router.push({
        name: "Courses",
      });
    },
  },
  computed: {
    ...mapState(["student", "linkVersus"]),
    ...mapGetters(["loggedIn"]),
  },
};
</script>

<style>
.icon_versus{
  width: 180px;
  margin: 15px;
}
.invite_text {
  font-size: 35px;
  line-height: 30px;
}
.content_invite {
  /*height: 100vh;*/
  position: relative;
}
.box_invite {
  background: white;
  /*height: 350px;*/
  padding: 20px;
  border-radius: 20px;
}
.button_actions_invite {
  border-style: none;
  width: 100%;
  height: 1.5rem;
  background: #f5f9fa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_click:hover {
  box-shadow: 5px 5px 15px -3px #76767687;
  cursor: pointer;
}
</style>
