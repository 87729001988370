<template>
  <div class="container">
    <invite />
  </div>
</template>
<script>
import invite from "@/components/student/invite/index.vue";

export default {
  name: "dataStudent",
  components: {
    invite,
  },
};
</script>
